'use strict';

import {sha512} from "js-sha512";

export default class API_connector{

    static api_base_url             = "https://api.zakaz.cloud"
    static api_base_dev_url         = "http://dev.api.zakaz.cloud"
    //static api_url                  = API_connector.api_base_dev_url
    static api_url                  = API_connector.api_base_url

    static selectel_path_domain     = "http://data.zakaz.cloud/d/";
    static selectel_self_path        = "https://202702.selcdn.ru/zakaz/d/";
    static selectel_path            = API_connector.selectel_self_path

    static token                    = localStorage.getItem("user-token") || "";
    static token_global             = localStorage.getItem("global-token") || "";

    static public_inner_token       = "lkifdjffd09IUJDLF";

    constructor(){
        //console.log('api init');
    }

    hello(){
        console.log('api hello');
    }

    async findAfisha(mode){

        const post_data = {

            global:{
                action:"find",
                module:"afisha"
            },
            data:{
                mode: mode
            }
        };

        let t = await this.post('/afisha', post_data, true);
        return t.data


    }

    async getPushkaTicketByRID(rid) {
        const post_data = {
            global: {
                format: 'raw',
                action: 'find',
                module: 'culture',
            },

            data: {
                mode: {
                    target: 'ticket',
                    type: 'getByRID',
                },
                rid: rid,
            },
        };

        let t = await this.post('/culture', post_data);
        return t.data;
    }

    async visitPushkaTicketByRID(rid) {
        const post_data = {
            global: {
                format: 'raw',
                action: 'find',
                module: 'culture',
            },

            data: {
                mode: {
                    target: 'ticket',
                    type: 'visitByRID',
                },
                rid: rid,
            },
        };

        let t = await this.post('/culture', post_data);
        return t.data;
    }




    async loadAfisha(type= false, actual = false){
        //console.log('loadAfsiha');

        let url = '/afisha' +
            '?type='+ Number(type) +
            '&actual='+ Number(actual);

        //let t = await this.post('/afisha?type=1&actual=1');
        //let t = await this.post('/afisha');
        let t = await this.post(url);
        //console.log(t);

        return t.data
    }


    async loadMongerList(){
        //console.log('loadMongerList');

        let t = await this.post('/mongerlist');
        //console.log(t);

        return t.data
    }

    async getPlayhouses(full = false){

        //console.log('1');

        const post_data = {

            global:{
                action:"find",
                module:"common"
            },
            data:{
                mode:{
                    target:"general",
                    part:"playhouse",
                    act:"list",
                    full: full
                }
            }
        };

        let t = await this.post('/common', post_data, true, true);
        return t.data

    }


    async loadEvent_Kassa(id){

        //console.log(id);

        let t = await this.post('/event/'+id+'/kassa');
        //console.log(t);

        return this.prepareEventData(t.data)
    }


    async addScan(data){

        // let api_base_url = 'http://dev.api.zakaz.cloud';
        let api_base_url = 'https://api.zakaz.cloud';

        let global = {};
        global['key']       = 'DFIOJLKfjs9870sdjkfld';
        global['format']    = 'raw';
        global['action']    = 'add';
        global['module']    = 'scan';

        let json = {};
        json['global'] = global;
        json['data']   = data;

        //console.log(json);

        let jj = JSON.stringify(json);
        //console.log(jj);


        let r = await fetch(api_base_url + '/scan',
            {
                method: "POST",
                body: jj
            })
        //     .then((response) => {
        //     //return response.json();
        // })

        return r.json();

        //return this.prepareEventData(t.data)

    }


    async loadEvent_Scan(id){

        //console.log(id);

        let t = await this.post('/event/'+id+'/scan?version=2');
        //console.log(t);

        return this.prepareEventData(t.data)
    }

    prepareEventData(data){

        // надо бы обработать, но меня устраивает json-format
        //console.log(data);

        return data;

    }

    async getUserMe(){

        let t = await this.get('/user/me');
        return t.data;
    }

    async auth(data){

        console.log('connector api auth');

        const post_data = {

            identifier:  data.username,
            //pass_hash:   hex_fucn.hex_sha512(data.password),
            pass_hash:   sha512(data.password),

            theater: data.theater_select,

            is_hash: true,
            is_admin: data.isAdmin,
        };

        return await this.post('/auth/local', post_data);

    }


    async post(url, data, json=true, isPublicQuery = false){

        //console.log('post');

        //let tk = localStorage.getItem("user-token");

        //API_connector.token;

        let headers = {
            'Authorization': 'Bearer '+ API_connector.token,
            'Content-Type': 'application/json'
        }

        // общий запрос, не от какого-либо театра, без токена
        if (isPublicQuery){

            data.global.key = API_connector.public_inner_token;

            headers = {
                'Content-Type': 'application/json'
            }
        }

        //console.log(data);
        let jj = JSON.stringify(data);
        //console.log(jj);
        //console.log(headers);

        let r = await fetch(API_connector.api_url + url,
            {
                method: "POST",
                body: jj,
                headers: headers
            })

        //console.log(r);

        if (json){
            //console.log(r.json);
            return r.json();

        }
        else{
            return r.text();
        }

    }


    async post_old(url, data){

        // let api_base_url = 'http://dev.api.zakaz.cloud';
        let api_base_url = 'https://api.zakaz.cloud';

        let global = {};
        global['key']       = 'DFIOJLKfjs9870sdjkfld';
        global['format']    = 'raw';
        global['action']    = 'get';
        global['module']    = 'event';

        let json = {};
        json['global'] = global;
        json['data']   = data;

        //console.log(json);

        let jj = JSON.stringify(json);
        //console.log(jj);


        let r = await fetch(api_base_url + url,
            {
                method: "POST",
                body: jj
            })
        //     .then((response) => {
        //     //return response.json();
        // })

        return r.json();

    }

    async get(url, json=true){

        let tk = localStorage.getItem("user-token");
        //console.log(tk);

        let r = await fetch(API_connector.api_url + url,
            {
                method: "GET",
                headers: {
                    //'Authorization': 'Bearer '+ API_connector.token,
                    'Authorization': 'Bearer '+ tk,
                    'Content-Type': 'application/json'
                }
            })

        if (json){
            return r.json();
        }
        else{
            return r.text();
        }

    }


}
