var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-system-bar',{staticStyle:{"white-space":"nowrap","text-overflow":"ellipsis","overflow":"hidden"},attrs:{"app":"","dark":""}},[_c('div',{staticClass:"d-flex justify-space-between mb-0"},[_c('div',{staticClass:"pa-0",staticStyle:{"white-space":"nowrap","text-overflow":"ellipsis","overflow":"hidden"}},[_vm._v(" "+_vm._s(_vm.qr_event_data.show)+" "+_vm._s(_vm.qr_event_data.date)+" ")])]),_c('v-spacer'),(_vm.mongerList.b)?_c('v-icon',[_vm._v("mdi-account")]):_c('v-icon',{staticClass:"red--text"},[_vm._v("mdi-account-off")]),_c('v-icon',{class:{'red--text':!_vm.qr_event_data.b}},[_vm._v("mdi-ticket")]),_c('v-icon',{attrs:{"color":_vm.getAfishaModeIcon.color}},[_vm._v(" "+_vm._s(_vm.getAfishaModeIcon.icon)+" ")]),_c('v-icon',{attrs:{"color":"blue"}},[_vm._v(" mdi-feather ")]),_c('v-icon',{attrs:{"color":_vm.getSettingsIconColor}},[_vm._v(" mdi-cog ")])],1),_c('v-app-bar',{staticClass:"green--text",attrs:{"app":"","dark":"","dense":""}},[_c('v-app-bar-nav-icon',{attrs:{"color":"white"},on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('div',{staticClass:"d-flex align-center"},[_vm._v(" ПроходЪ ")]),_c('v-spacer'),_vm._v(" "+_vm._s(_vm.getProfileInfo.playhouse.name)+" ")],1),_c('v-navigation-drawer',{attrs:{"app":"","dark":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',[_c('v-list-item',{staticClass:"pa-4 grey darken-2"},[_c('v-img',{attrs:{"src":"https://zakaz.cloud/head.png","aspect-ratio":"1.7","height":"105","contain":""}})],1),_c('br'),_c('v-list-item-group',{attrs:{"active-class":"dark--text"},model:{value:(_vm.menu_selected),callback:function ($$v) {_vm.menu_selected=$$v},expression:"menu_selected"}},_vm._l((_vm.links_scanner),function(ref){
var icon = ref[0];
var text = ref[1];
var color = ref[2];
var r_link = ref[3];
var need_div = ref[4];
return _c('router-link',{key:icon,attrs:{"to":r_link},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('v-list-item',{class:[isActive && 'router-link-active', isExactActive && 'router-link-exact-active'],on:{"click":navigate}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":color}},[_vm._v(_vm._s(icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"green--text text--lighten-1"},[_vm._v(_vm._s(text))])],1)],1)]}}],null,true)})}),1)],1)],1),_c('v-main',{staticClass:"grey darken-1",staticStyle:{"word-wrap":"break-word"}},[_c('router-view')],1),_c('v-bottom-navigation',{attrs:{"app":"","background-color":_vm.getBottomColor,"dark":"","shift":""},model:{value:(_vm.bottom_menu_index),callback:function ($$v) {_vm.bottom_menu_index=$$v},expression:"bottom_menu_index"}},[_c('v-btn',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('span',[_vm._v("Меню")]),_c('v-icon',[_vm._v("mdi-menu")])],1),_c('v-btn',{attrs:{"to":"/active"}},[_c('span',[_vm._v("Сегодня")]),_c('v-icon',[_vm._v("mdi-calendar-today")])],1),_c('v-btn',{attrs:{"to":"/camera"}},[_c('span',[_vm._v("Камера")]),_c('v-icon',[_vm._v("mdi-qrcode-scan")])],1),_c('v-btn',{attrs:{"to":"/base"}},[_c('span',[_vm._v("База")]),_c('v-icon',[_vm._v("mdi-ticket")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }