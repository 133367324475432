import Vue from "vue";
import {AFISHA_WORK_MODE, EVENT_STATUS, CAMERA_TYPE} from "@/core/common";


const state = {

    settings:{

        // afisha | day | event
        work_mode: AFISHA_WORK_MODE.EVENT,

        event_status: [EVENT_STATUS.INET_OPEN],

        isEmulate: false,           // режим эмуляции билетов
        isComing:  false,           // только предстоящие
        isEntry:   false,           // только на вход - нужно, чтоб из афиши подгружалась с event_type=1

        isDefaultSettings: true,    // ни разу не заходили в настройки и не сохраняли их локально

        camera: CAMERA_TYPE.AUTO,

        size:{
            form_width: 350,
            scan_area_height: 150,
            margins: 2
        },


        // TODO задел на будущее
        // + подумать как объединить некоторые режимы в одну настройку

        isOnlineScan: false,        // по каждому билету спрашивать решение у API и дожидаться ответа
        shouldPutOutPushka: false,  // гасить пушкинские в режиме онлайн

        isSoftScan: false,          // позволить ошибки при офлайн парсинге билета

        saveToLocalBase:false,      // сохранять сканированное в локальную базу для дальнейшей выгрузке

        // -- //



    }



};

const getters = {

    getSettings:                state=> state.settings,

    getSettingsWorkMode:        state=> state.settings.work_mode,

};

const actions = {

    resetSettings:({commit}) =>{

        commit("deleteSetting");
        commit("loadSetting");

    },

    saveSettings:({commit}, data) =>{

        //console.log(data, commit);

        commit("setEventStatus",    data.event_status);

        commit("setWorkMode",       data.work_mode);
        commit("setCamera",         data.camera);

        commit("setIsEmulate",      data.isEmulate);
        commit("setIsComing",       data.isComing);
        commit("setIsEntry",        data.isEntry);

        commit("setSize",          data.size);

        commit("setIsDefaultSettings",  false);
        commit("saveSetting");
        //commit("loadConfig");

    }


};

const mutations = {

    saveSetting:(state)=>{

        localStorage.setItem("config-scan", JSON.stringify(state.settings));

    },

    deleteSetting:()=>{

        localStorage.removeItem("config-scan");

    },

    loadSetting:()=>{

        let settings = JSON.parse(localStorage.getItem("config-scan"));

        if (settings==null){
            console.log('default');


            Vue.set(state.settings, "work_mode", 'event');
            Vue.set(state.settings, "camera", CAMERA_TYPE.AUTO);
            Vue.set(state.settings, "event_status", [EVENT_STATUS.INET_OPEN.value]);
            Vue.set(state.settings, "isEmulate", false);
            Vue.set(state.settings, "isComing",  false);
            Vue.set(state.settings, "isEntry",   false);

            Vue.set(state.settings, "size", {
                form_width: 350,
                scan_area_height: 150,
                margins: 2
            });
            Vue.set(state.settings, "isDefaultSettings",   true);
        }
        else{
            console.log('local storage')

            Vue.set(state.settings, "work_mode",    settings.work_mode);
            Vue.set(state.settings, "camera",       settings.camera);
            Vue.set(state.settings, "event_status", settings.event_status);
            Vue.set(state.settings, "isEmulate",    settings.isEmulate);
            Vue.set(state.settings, "isComing",     settings.isComing);
            Vue.set(state.settings, "isEntry",      settings.isEntry);
            Vue.set(state.settings, "size",        settings.size);
            Vue.set(state.settings, "isDefaultSettings",   false);

        }

        console.log(settings);

    },


    setWorkMode:(state, value)=>{
        Vue.set(state.settings, "work_mode", value);
    },

    setEventStatus:(state, value)=>{
        Vue.set(state.settings, "event_status", value);
    },

    setIsEmulate:(state, value)=>{
        Vue.set(state.settings, "isEmulate", value);
    },

    setIsComing:(state, value)=>{
        Vue.set(state.settings, "isComing", value);
    },

    setIsEntry:(state, value)=>{
        Vue.set(state.settings, "isEntry", value);
    },

    setCamera:(state, value)=>{
        Vue.set(state.settings, "camera", value);
    },

    setIsDefaultSettings:(state, value)=>{
        Vue.set(state.settings, "isDefaultSettings", value);
    },

    setSize:(state, value)=>{

        //console.log(value);

        Vue.set(state.settings.size, "form_width", value.form_width);
        Vue.set(state.settings.size, "scan_area_height", value.scan_area_height);
        Vue.set(state.settings.size, "margins", value.margins);
    },



};

export default {
    state,
    getters,
    actions,
    mutations
};
