import Vue from 'vue'
import Vuex from 'vuex'

import user from "./modules/user";
import scan from "./modules/scan"
import settings from "./modules/settings"
import API_connector from "@/core/API_connector";

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  state: {
    api: new API_connector(),
  },
  // mutations: {
  // },
  // actions: {
  // },
  modules: {
    user,
    scan,
    settings
  },
  strict: debug
})
