'use strict';

export const AFISHA_WORK_MODE = {

    NONE: "",
    AFISHA: "afisha",
    DAY: "day",
    EVENT: "event"
}

// тип камеры для сканирования
export const CAMERA_TYPE ={
    OFF: "off",
    AUTO: "auto",
    REAR: "rear",
    FRONT: "front"
}

export const TICKET_LEVEL = {
    TOTAL_BAD: 0,
    EMULATE_GOOD: 1,
    LOCAL_BASE_GOOD: 2,
    INET_GOOD: 3
}

export const ACCOUNTING_TYPE = {

    NONE:       "none",
    INET:       "I",
    KASSA:      "K",
    MONGER:     "M",
    REMOTE:     "R"
}

export const ACCOUNTING_GROUP = {

    NONE:    "none",
    THEATER: "THEATER",
    MONGER:  "MONGER",
    REMOTE:  "REMOTE",
}

export const SEAT_STATUS = {

    SALE_INET:       15,
    SALE_REMOTE:     14,

    BRON_INET_TEMP:  7,

    SALE_KASSA_NAL:  12,
    SALE_KASSA_CARD: 13,

    BRON_KASSA_TEMP: 34,

    BRON_MONGER_WITHOUT_MO: 91,
    BRON_MONGER_MO:         92,
    BRON_MONGER_LINK:       95,

}

export const EVENT_STATUS = {

    INET_OPEN:{
        name:"Продажа везде",
        value: 32
    },

    KASSA_OPEN:{
        name:"Продажа на кассе",
        value: 62
    },

    NEW:{
        name:"Новые",
        value: 31
    }



}


export const commonZ = {

    // добавить данных по билету и дописать нужные флаги
    sussQRfromBase_infoline (current_scan_info, qr_event_data, mongerList, scan_statistics_local){

        console.log(current_scan_info, qr_event_data, mongerList, scan_statistics_local);

        // если флаг isToday, а он пока всегда стоит, когда мы вызываем эту функуцию
        current_scan_info.suss.show = qr_event_data.show;
        current_scan_info.suss.date = qr_event_data.date;

        // определяем продавца

        qr_event_data.data.forEach(base_qr=>{

            // совпадает кресло и платежка - или кресло и mo - в зависимости от продавца - надо
            // monger list подгружать и смотреть
            if ((current_scan_info.info.seat_id===base_qr.id)&&
                (current_scan_info.info.payment===base_qr.payment)){

                current_scan_info.g = true;
                current_scan_info.suss.sector = base_qr.sector;
                current_scan_info.suss.row    = base_qr.row;
                current_scan_info.suss.seat   = base_qr.chair;

                if (!base_qr.scanned){
                    scan_statistics_local.total.count++;

                    // приплюсовать в статистику к нужному продавцу
                    // топорно - надо сопоставлять с загруженным списком продавцов !!
                    if (current_scan_info.info.monger_mark=="IM"){
                        // так тоже нельзя - надо через find - певрым может быть любой
                        scan_statistics_local.event[0].count++;
                    }

                    if ((current_scan_info.info.monger_mark=="KSK")||(current_scan_info.info.monger_mark=="KSD")){
                        // так тоже нельзя - надо через find - певрым может быть любой
                        scan_statistics_local.event[1].count++;
                    }

                    if (current_scan_info.info.monger_mark=="USY"){
                        // так тоже нельзя - надо через find - певрым может быть любой
                        scan_statistics_local.event[2].count++;
                    }

                    if (current_scan_info.info.monger_mark=="UM"){
                        // так тоже нельзя - надо через find - певрым может быть любой
                        scan_statistics_local.event[3].count++;
                    }





                    base_qr.scanned = true;
                }
                else{
                    //console.log('Уже сканировали билет - подсветить его синим!');
                }



                //console.log('Билет правильный');
                //bb1 = true;
            }

        });





    }

}



export default commonZ

