import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store";

Vue.use(VueRouter)


const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/login");
};

const doLogout = (to, from, next) => {

  store.commit("authReset");

  next("/login");
};


const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home'),
    beforeEnter: ifAuthenticated
  },

  {
    path: '/login/:id(\\d+)?',
    name: 'login',
    component: () => import('../components/LoginAdmin/LoginAdmin'),
    beforeEnter: ifNotAuthenticated
  },

  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/Home'),
    beforeEnter: doLogout
  },


  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')

  },

  {
    path: '/scan',
    name: 'Scan',
    component: () => import('../views/scanner/Scan.vue'),
    beforeEnter: ifAuthenticated
  },

  {
    path: '/camera',
    name: 'Camera',
    component: () => import('../views/scanner/camera/Camera.vue'),
    beforeEnter: ifAuthenticated
  },

  {
    path: '/stat',
    name: 'Stat',
    component: () => import('../views/scanner/Statistics.vue'),
    beforeEnter: ifAuthenticated
  },

  {
    path: '/begin',
    name: 'Begin',
    component: () => import('../views/scanner/Begin.vue'),
    beforeEnter: ifAuthenticated
  },

  {
    path: '/active',
    name: 'Active',
    component: () => import('../views/scanner/Active.vue'),
    beforeEnter: ifAuthenticated
  },

  {
    path: '/base',
    name: 'Base',
    component: () => import('../views/scanner/base/Base.vue'),
    beforeEnter: ifAuthenticated
  },

  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/scanner/settings/Settings'),
    beforeEnter: ifAuthenticated
  },



]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,


  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }

})

export default router
