<template src="./App.html"></template>

<script>

import router from "@/router/routes";
import { mapGetters, mapState } from "vuex";
import {AFISHA_WORK_MODE} from "@/core/common";


export default {
  name: 'App',

  components: {

  },

  data: () => ({

    drawer: false,
    menu_selected:0,

    bottom_menu_index: 0,

    links_scanner: [
      ['mdi-calendar-today',                'Сегодня',            'blue lighten-1',          '/active',    true],
      ['mdi-home-outline',                  'База билетов',       'purple lighten-2',         '/base',      true],
      // ['mdi-data-matrix-scan',              'Сканировать',        'yellow',                  '/scan',      false],
      ['mdi-camera',                        'Камера',             'red',                   '/camera',    false],
      // ['mdi-chart-bar',                     'Статистика',         'green darken-1',           '/stat',      true],
      ['mdi-cog',                           'Настройки',          'grey',                   '/settings',  true],
      ['mdi-ticket-confirmation-outline',   'ЗаказЪ',             'amber darken-3',         '/about',     true],
    ],

  }),

  computed: {

    ...mapGetters([
      "isAuthenticated",
      "getProfileInfo",
      "getSettings",
      "getStorage"
    ]),

    ...mapState({
      qr_event_data:      state => state.scan.qr_event_data,
      mongerList:         state => state.scan.mongerList,
      current_scan_info:  state => state.scan.current_scan_info,
      name: state => `${state.user.profile.role.name} ${state.user.profile.username}`
    }),

    getBottomColor(){

      switch (this.bottom_menu_index) {
        case 0: return 'grey darken-3'
        case 1: return 'grey darken-3'
        case 2: return 'grey darken-4'
        case 3: return 'grey darken-3'
        default: return 'blue-grey'
      }

    },

    getSettingsIconColor(){
      return this.getSettings.isDefaultSettings? 'red': "blue"
    },

    getAfishaModeIcon(){

      let r = {
        color:"red",
        icon: "mdi-account"
      }

      console.log(this.getSettings);

      switch (this.getSettings.work_mode){

        case AFISHA_WORK_MODE.AFISHA:
          r.icon = "mdi-calendar-month";
          r.color = "purple";
          break;

        case AFISHA_WORK_MODE.DAY:
          r.icon = "mdi-calendar-today";
          break;

        case AFISHA_WORK_MODE.EVENT:
          r.icon = "mdi-calendar-star";
          r.color = "red";

          if (this.getStorage.flag.isOneEventLoaded){
            r.color = "green";

            if (this.getSettings.isOffline){
              r.color = "purple";
            }
          }

          break;

        default:
          r.icon = "mdi-calendar-question";
          break;
      }

      //r.color = this.getSettings.isOffline? "purple" : "green";

      if (!this.getStorage.flag.isOfflineAfishaLoaded){
        r.icon  = "mdi-calendar-remove";
        r.color = "red";
      }












      return r;

    }
  },

  created() {

    // настройки работы сканера - важно загрузить прежде всего
    this.$store.commit("loadSetting");

    // авторизация, билеты, продавцы
    this.$store.dispatch("loadScanConfig");

    // подгружаем базу сканирований
    this.$store.commit("loadLocalTicketScanBase");



    window.addEventListener('keydown', this.onScan, { passive: true } )
    //window.addEventListener('beforeunload', this.before)

  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onScan)
    //window.removeEventListener('beforeunload', this.before)
  },


  mounted(){
    // грузим на страницу репертуара - список активных мероприятий
    //this.$store.commit('loadAfishaScan');

    // грузим продавцов
    //this.$store.commit('loadMongerList');

    // грузим сразу маугли 1 - 17 в 17-00
    //this.$store.commit('loadEventScan', 7);


  },

  methods: {

    before(e){
      console.log('199976878678678')
      e.preventDefault()
      e.returnValue = ''

    },


    // слишком медленно обрабатывает, если его глобально установить
    onScan(e){

      // спец кнопка-курок, которая никак не отствечивает =(
      if ((e.key=="Unidentified")&&(e.keyCode==0)){
        if (this.$router.currentRoute.name!=='scan'){
          router.push('scan');
        }
      }




      //console.log(e);

      // if (e.code<1){
      //   return
      // }
      //
      // if (e.key==="Shift"){
      //   return
      // }
      //
      // if (e.code==="Space"){
      //   return
      // }
      //
      //
      // // enter
      // if (e.key==="Enter"){
      //   this.i=0;
      //   this.txt_scan_infoline = this.txt_typing;
      //   this.txt_typing = "";
      //
      //   // прописать условия
      //   // если загружена база, то ищем в ней
      //   //this.$store.commit('doScan_infoline', this.txt_scan_infoline);
      //
      //   // если нет базы - посылаем прямой запрос на север
      //
      //   // если и сети нет - ругаемся =(
      //
      //   if (this.$router.currentRoute.name!=='Scan'){
      //     router.push('Scan');
      //   }


      // }
      // else{
      //   this.txt_typing += e.key;
      // }

    }

  }

};
</script>

<style>

html, body{
  overscroll-behavior-y:contain;
}

#app{
  overscroll-behavior:none;
}

</style>
