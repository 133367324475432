
import Vue from "vue";

import router from "../../router/routes";
import API_connector from "@/core/API_connector";


const state = {

  api: new API_connector(),

  status:         "",

  // новый для отрисовки и прочего
  // перенести сюда все потихоньку
  profile_info:{

    isLoaded:     false,
    isGlobalUser: false,
    menu:         'none',

    user:{

      id:    0,
      role_id:    0,
      email:      "mail@mail.ru",
      login_time: "15 июня 20-49",

      role_name:  "роль",
      role_color: "red",
      foto:       "https://cdn.vuetifyjs.com/images/john.jpg",

      token:      localStorage.getItem("user-token") || "",

    },

    monger:{

      id:  0,

      family:     "Фамилия",
      name:       "Имя",
      fname:      "Отчестов",

      mark:       "FIO",
      phone:      "+7",

    },

    playhouse:{

      id: 0,
      name:   "НКО Магнес",
      path:   "",
      folder: ""
    },

  },

};

const getters = {

  isAuthenticated:  state => !!state.profile_info.user.token,
  getProfileInfo:   state => state.profile_info,
  isAdmin:          state => state.profile.role.type === 'admin',
};

const actions = {

  getUserMe:({commit}) =>{

    state.api.hello();

    // достать из апи инфу о зрителе
    return state.api.getUserMe()
        .then(resp=>{

          console.log('get_user_me');
          commit('setUserInfo', resp);

          return resp;

        })
        .catch(err => {
          console.log('errr');
          console.log(err);
        });

  },


  auth({commit, dispatch, state}, data){

    console.log('auth action');

    state.api.auth(data)
        .then(resp=>{

          console.log('auth_user');

          if (resp.code!=400){
            localStorage.setItem("user-token", resp.token);
            // commit(AUTH_SUCCESS, resp);
            commit('authGood', resp);

            // dispatch(USER_REQUEST);
            //dispatch('getUserMe');
            dispatch("loadScanConfig");
          }
          else{
            commit('authBad', resp);
          }
        })
        .catch(err => {
          console.log('errr');
          console.log(err);
        });

    //console.log('auth complete');

  },

  logout:({commit}) =>{

    console.log('exit action');

    let profile_reset = {

      local_user:    false,
      confirmed:     false,
      menu:          "",

      user:{
        id:    0,
        role_id:    0,
        email:      "",
        login_time: "",

        role_name:  "",
        role_color: "grey",
        foto:       "",
      },

      profile:{
        id:  0,
        family:     "",
        name:       "",
        fname:      "",
      },

      place: 0,
      place_name:"Театр",
      path:"#",
      folder:"ALL"
    };

    commit('setUserInfo', profile_reset);

    localStorage.removeItem("user-token");
    commit('setDefaultNAMenu');

    router.push("/");

    console.log('exit complete');
    //router.push("/login");
    // dispatch('loadConfig')
  },



};

const mutations = {

  authGood:(state, data) =>{

    console.log('authGood');
    state.profile_info.user.token = data.token;

  },

  authBad:(state, data) =>{

    console.log('auth bad');
    state.profile_info.user.token = "";

    console.log(data);

  },

  authReset:(state) =>{

    console.log('auth reset');

    localStorage.setItem("user-token", "");
    localStorage.setItem("admin-token", "");

    state.profile_info.user.token = "";

  },

  setUserInfo: (state, payload) =>{

    //console.log('set user info');

    // user base
    Vue.set(state.profile_info.user, "id",          payload.id);
    Vue.set(state.profile_info.user, "role_id",     payload.role);
    Vue.set(state.profile_info.user, "email",       payload.email);
    Vue.set(state.profile_info.user, "login_time",  payload.time);

    let role_name   = "роль не определена";
    let role_color  = 'dark';
    let mark        = "U";

    switch (payload.role){
      case 100:
        role_name = "Мастер";
        role_color = "deep-purple";
        mark = 'M';
        break;

      case 50:
        role_name = "Администратор";
        role_color = "red";
        mark = 'A';
        break;

      case 30:
        role_name = "Кассир";
        role_color = "orange";
        mark = 'K';
        break;

      case 1:
        role_name = "Зритель";
        role_color = "green";
        mark = "З";
        break;

      default:
        role_name = "Некто";
        role_color = "lime";
        mark = 'Х';
        break;
    }

    Vue.set(state.profile_info.user, "role_name",   role_name);
    Vue.set(state.profile_info.user, "role_color",  role_color);
    Vue.set(state.profile_info.user, "foto",  0);

    // monger
    Vue.set(state.profile_info.monger, "id", 0);

    Vue.set(state.profile_info.monger, "family", payload.profile.family);
    Vue.set(state.profile_info.monger, "name",   payload.profile.name);
    Vue.set(state.profile_info.monger, "fname",  payload.profile.fname);

    Vue.set(state.profile_info.monger, "mark",  mark);
    Vue.set(state.profile_info.monger, "phone", 0);

    // playhouse
    Vue.set(state.profile_info.playhouse, "id",     payload.place);
    Vue.set(state.profile_info.playhouse, "name",   payload.place_name);
    Vue.set(state.profile_info.playhouse, "path",   payload.path);
    Vue.set(state.profile_info.playhouse, "folder", payload.folder);

    // common
    Vue.set(state.profile_info, "menu",           payload.menu);
    Vue.set(state.profile_info, "isGlobalUser",   !payload.local_user);
    Vue.set(state.profile_info, "isLoaded",       payload.confirmed);

    //console.log('ready user info');

  },

};

export default {
  state,
  getters,
  actions,
  mutations
};
