import API_connector from "@/core/API_connector";
import {
  commonZ,
  TICKET_LEVEL,
  AFISHA_WORK_MODE,
  SEAT_STATUS,
  ACCOUNTING_TYPE,
  ACCOUNTING_GROUP
} from "@/core/common";

import user from "./user";
import settings from "./settings";
import Vue from "vue";

const state = {

  api:new API_connector(),

  token:'',

  storage:{

    flag:{
      isEmulateAfishaLoaded: false,
      isOneEventLoaded: false,
      isDayEventsLoaded: false,
    },

    events: [],
    layers: {},
    sectors:[],
    ticket_types:[],

    day_events:[],

    one_event: {
      id: 0,
      accounting: {
        mo_data:[],
        mo_ids:[],
        payments_data:[],
        payments_ids:[],
        pushka:[],

      },
      seats: {},
    },

  },

  // текущее сканирование (новое)
  ticket:{

    flag:{
      isReady:    true,
      isLoading:  false,
      isError:    true,

      isPushka: false,

      isPushkaReady: false,
      isPushkaLoading: false,
      isPushkaActive: false,
      isPushkaVisited: false,


      // потенциально существует как сочетание event_id - seat_id
      isEmulateGood: false,
      isEmulateOneEventGood: false,
      isLocalBaseGood: false,

      // билет отсканирован один раз
      isOriginal: true,
    },

    level: TICKET_LEVEL.LOCAL_BASE_GOOD,

    info:{
      QR: "Содержимое сканирования",
      type: ACCOUNTING_TYPE.NONE,
      group: ACCOUNTING_GROUP.NONE,
      mark: "IM",
      monger_id: 0,
      original_id: 0,
      scan_mode: AFISHA_WORK_MODE.NONE,
      time: new Date().toISOString().slice(0, 19).replace('T', ' '),
    },

    show:{
      id:0,
      name: "Сканер готов",
      age: "0+"
    },

    event:{
      id: 0,
      date:{
        day: 5,
        month: "дата проведения",
        year: 2023,
        time: "18:00",
      }

    },

    seat:{
      id:0,
      sector: 1,
      sector_name: "Место",
      row: 5,
      chair: 15,

    },

    accounting:{
      id:0,
      count: 0,
      status: 0,
      price: 0,
      date: "",

    },

    pushka:{
      rid:"",
      fio: "%Фамилия по карте%"
    }

  },


  // array of tickets
  base_scanned_ticket:[],

  // api_json_event_map for kassa map
  eventMapKassa:{
    b:false,
    data:{}
  },

  // api_json_afisha - list - список акутальных мероприятий
  eventAfishaScan:{
    b:false,
    r:false,    // reloading
    data:{}
  },

  // api_json_event_scan data - мероприятие сейчас
  currentEventScan:{
    b:false,
    data:{
      event:{},
      layout:{},
      legend_group:{},
      price_map:{},
      prices:{},
      seats:{},
      sectors:{},
      show:{}
    },
    event:0
  },

  // список продавцов
  mongerList:{
    b:false,
    r:false,
    data:[
      {
        desc:'',
        id:0,
        mark: 'MRK',
        name: 'FIO',
        role_id:0,
        user_id:0
      }
    ]
  },

  // ready qr data from - а тут все места с qr на сегоднешнее мероприятие
  qr_event_data:{
    b:false,
    r:false,    // reloading
    event: 0,
    show: "[Не загружено]",
    date:"[нет данных]",
    time:"",
    age:"",

    data: []
  },

  // что было отсканировано за сегодня на этом устройстве
  scan_statistics_local:{

    event:[
      {
        order:      0,          // 1 инет, 2 касса, 3 уполномоч
        name:       "x",        // fio
        guess:       0,         // сколько продано
        mark:        "x",       // IM KS USH
        group_mark:  "xg",      // IM KS MGN
        count:       0,         // сколько уже отсканировали
        color:       "black"
      }
    ],
    error:[],
    total:{}

  },

  // то, что отсканировали и какой билет ищем
  current_scan_info:{
    b: false,       // распознали
    r: false,       // в процессе еще
    g: false,       // билет на тот спектакль?


    txt_scan: "Готов",

    // статусы
    flag:{

      // обработка строки
      isRun:        false,
      isOurQR:      false,
      isParseOk:    false,
      isDoubleNow:  false,

      // добываем добавочную инфу
      isRunAdd:     false,
      isToday:      false,    // спектакль совпадает с подгруженным?
      inAfisha:     false,    // спектакль в актуальной афише? - а тут 62 выгружаются?!!!
      inBaseQR:     false,    // билет был заранее загружен в базу QR

    },

    // то что распознали из QR
    info:{
      event:0,

      monger_mark:"",
      serial:"",
      seat_id:0,
      paydoc:0,         // платежный документ mo или payment

    },

    // то что расшифровали и дописали из внутренних данных и вычислений
    suss:{

      show:         "[нет данных]",
      date:         "x",
      sector:       "x",
      row:          "x",
      seat:         "x",

      monger_name:  "x",
      monger:       0,
      payment:      0,
      mo:           0,

      group_mark:   "x",
      monger_mark:  "x",

    }
  },

};

const getters = {

  getStorage:       state => state.storage,
  getTicket:        state => state.ticket,
  getTicketBase:    state => state.base_scanned_ticket,

};

const actions = {

  getTicketByRID:({state}, data) => {

    let base_ticket = state.base_scanned_ticket.find(ticket=>ticket.id === data.id);
    console.log(base_ticket);

    console.log(state, data);

    // TODO так нельзя, надо переписывать на внутрь mutation

    Vue.set(base_ticket.flag, "isPushkaReady", false);
    Vue.set(base_ticket.flag, "isPushkaLoading", true);

    state.api.getPushkaTicketByRID(data.rid).then((data_r) => {
      console.log(data_r);
      console.log(data_r.status);

      // TODO так нельзя, надо переписывать на внутрь mutation

      Vue.set(base_ticket.flag, "isPushkaReady", true);
      Vue.set(base_ticket.flag, "isPushkaLoading", false);
      Vue.set(base_ticket.flag, "isPushkaActive", (data_r.status === 'active'));
      Vue.set(base_ticket.flag, "isPushkaVisited", (data_r.status === 'visited'));

    });

  },

  setVisitPushkaTicketByRID:({state}, data) => {

    let base_ticket = state.base_scanned_ticket.find(ticket=>ticket.id === data.id);
    console.log(base_ticket);

    console.log(state, data);

    // TODO так нельзя, надо переписывать на внутрь mutation

    Vue.set(base_ticket.flag, "isPushkaLoading", true);

    state.api.visitPushkaTicketByRID(data.rid).then((data_r) => {

      console.log(data_r);
      console.log(data_r.status);

      // TODO так нельзя, надо переписывать на внутрь mutation

      Vue.set(base_ticket.flag, "isPushkaReady", true);
      Vue.set(base_ticket.flag, "isPushkaLoading", false);
      Vue.set(base_ticket.flag, "isPushkaActive", (data_r.status === 'active'));
      Vue.set(base_ticket.flag, "isPushkaVisited", (data_r.status === 'visited'));

    });

  },




  // подгрузить данные для загрузки мероприятия на сканер
  // eslint-disable-next-line no-unused-vars
  loadAfishaScan:({state, commit})=>{

    commit("setAfishaStatusB", false );
    commit("setAfishaStatusR", true );

    // переделать в мутацию
    Vue.set(state.storage.flag, "isEmulateAfishaLoaded", false);

    let only_entry = [true, false];
    if (settings.state.settings.isEntry){
      only_entry = [true];
    }

    let mode = {
      type: "scan",
      actual: settings.state.settings.isComing,
      status: settings.state.settings.event_status,
      only_entry: only_entry,
      limit: 500
    }
    state.api.findAfisha(mode).then( data => {

      console.log(data);
      commit("setStorage", data);

    });

    console.log('111');



    state.api.loadAfisha(
        settings.state.settings.isEntry,
        settings.state.settings.isComing,
    )
      .then( data =>{

        commit("setAfishaStatusB", true );
        commit("setAfishaData", JSON.parse(JSON.stringify(data)) );


      })
    //console.log('3');
  },

  // получить данные по сегодняшнему спектаклю
  loadEventScan:({state, commit}, event_id)=>{

    //console.log('4');

    commit("setQR_eventB", false );
    commit("setQR_eventR", true );

    Vue.set(state.storage.flag, "isOneEventLoaded", false);

    // state.qr_event_data.b = false;
    // state.qr_event_data.r = true;

    state.api.loadEvent_Scan(event_id)
      .then( data =>{

        commit("setQR_eventB", true);
        commit("setQR_eventID",  event_id);

        //commit("setQR_eventData",  JSON.parse(JSON.stringify(data)) );
        console.log(data);

        commit("setStorageOneEvent", data);




        // oldddd

        commit("setCurrentEventScan",  JSON.parse(JSON.stringify(data)) );

        // state.currentEventScan = {
        //   b:true,
        //   event: event_id,
        //   data: data
        // };
        //console.log(state.currentEventScan);

        // подготовить ожидаемые билеты - локально
        commit('compileLegend');

        // обработать и сформировать массив qr
        commit('compileQRData');
        //console.log('99');


    })
    //console.log('5');
  },

  loadMongerList:({state, commit})=>{
    //console.log(state);

    // state.mongerList.b = false;
    // state.mongerList.r = true;

    commit("setMongerListB", false );
    commit("setMongerListR", true );

    state.api.loadMongerList()
    .then( data =>{

      commit("setMongerListB", true );
      commit("setMongerListR", false);

      commit("setMongerListData", JSON.parse(JSON.stringify(data)) );

      // state.mongerList = {
      //   b:true,
      //   r:false,
      //   data: data
      // }
    })
    //console.log('4');


  },



  doScanNew({state, commit, dispatch}, line){

    // clear ticket
    commit("clearTicket", line);

    console.log(line);

    let parse_error = false;

    let monger_mark = "";
    let event_id = 0;
    let serial = "";
    let seat_id = 0;
    let accounting_id = 0;

    try {

      let s1 = line.split('!');
      //console.log(s1);

      let s2 = s1['1'].trim().split(" ");
      let qr = s2['1'].trim().split("-");

      monger_mark = s2['0'].trim();
      event_id = parseInt(qr['0']);
      serial = qr['1'];
      seat_id = parseInt(qr['2']);
      accounting_id = parseInt(qr['3']);

    } catch (err){
      console.warn(err.name);
      parse_error = true;

      commit("setTicketReady", false);

    } finally {
      console.log('fin');
    }

    if (!parse_error){

      console.log(monger_mark, event_id, serial, seat_id,  accounting_id);
      console.log(settings.state.settings.isEmulate)

      // общая афиша и эмуляция - всегда проверяем
      let isEmulateGood = motion.searchTicketInEmulateMode(event_id, seat_id);
      console.log(isEmulateGood);

      if (isEmulateGood.b){
        console.log("em good");
        commit("setTicketGoodEmulation", isEmulateGood);

        let isOneLocalBaseGood = {};

        //console.log(settings.state.settings);

        switch (settings.state.settings.work_mode){

          case AFISHA_WORK_MODE.AFISHA:

            console.log("em afisha");
            commit("setTicketReady", true);
            break;

          case AFISHA_WORK_MODE.DAY:
            break;

          // конкретно по одному мероприятию
          case AFISHA_WORK_MODE.EVENT:

            console.log("em event");

            // мероприятие загружено
            if (state.storage.one_event.id > 0){

              isOneLocalBaseGood = motion.searchTicketInOneEvent(event_id, seat_id, accounting_id);
              isOneLocalBaseGood.mark = monger_mark;

              console.log(isOneLocalBaseGood);

              if (isOneLocalBaseGood.b){

                console.log("one local base  good");
                commit("setTicketGoodLocalBase", isOneLocalBaseGood);
                commit("setTicketReady", true);

              }
            }
            else {
              commit("setTicketReady", false);
            }

            break;
        }

      }

      else {
        console.log('total bad ticket')
        commit("setTicketReady", false);
      }

    }

    dispatch('setCopyDataToTicket');

    console.log('addTicketToScanBase start');
    commit("addTicketToScanBase");

    commit("saveLocalTicketScanBase");

    console.log('scan_end');

  },

  setCopyDataToTicket:({state, commit}) =>{

    let ticket_original = state.base_scanned_ticket.find(ticket_base => ticket_base.info.QR === state.ticket.info.QR);
    console.log(ticket_original);

    if (ticket_original !== undefined){
      console.log("copy");
      commit("setTicketCopy", ticket_original.id);
    }

    console.log('setCopyDataToTicket done');

    //new Date().toLocaleString(['ru-RU'])
    //time: new Date().toISOString().slice(0, 19).replace('T', ' '),
    //new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' ');


  },


  // eslint-disable-next-line no-unused-vars
  loadScanConfig:({commit, dispatch}) =>{

    console.log("loadScanConfig");

    // проверка на авторизаци2ю
    if (user.getters.isAuthenticated(user.state)) {

      console.log('find token');

      dispatch("getUserMe").then(()=> {

        console.log('user loaded');

        // грузим на страницу репертуара - список активных мероприятий
        dispatch('loadAfishaScan');

        // грузим продавцов
        dispatch('loadMongerList');

        // грузим сразу маугли 1 - 17 в 17-00
        //dispatch('loadEventScan', 1);


      });
    }

    else{
      // не авторизован
      console.log('no token');

    }

  },

};

const mutations = {

  saveLocalTicketScanBase:(state)=>{
    localStorage.setItem("local-scan-base", JSON.stringify(state.base_scanned_ticket));
  },

  deleteLocalTicketScanBase:()=>{

    localStorage.removeItem("local-scan-base");
    Vue.set(state, "base_scanned_ticket", []);

  },

  loadLocalTicketScanBase:()=>{

    let localScans = JSON.parse(localStorage.getItem("local-scan-base"));

    if (localScans==null){
      console.log('default');
      Vue.set(state, "base_scanned_ticket", []);
    }
    else {
      console.log('need catch error try!!!');
      Vue.set(state, "base_scanned_ticket", localScans);
    }

  },

  addTicketToScanBase(state){

    let ticket = JSON.parse(JSON.stringify(state.ticket));

    ticket.id = state.base_scanned_ticket.length;
    state.base_scanned_ticket.unshift(ticket);

  },

  // повторяет код emuReady - порефакторить
  setTicketReady(state, isGood){

    Vue.set(state.ticket.flag, "isReady", true);
    Vue.set(state.ticket.flag, "isLoading", false);
    Vue.set(state.ticket.flag, "isError", !isGood);

  },

  setTicketCopy(state, original_id){

    Vue.set(state.ticket.flag, "isOriginal", false);
    Vue.set(state.ticket.info, "original_id", original_id);

  },




  setTicketGoodLocalBase(state, data){

    Vue.set(state.ticket.flag, "isLocalBaseGood", data.b);

    Vue.set(state.ticket.flag, "isPushka", data.isPushka);

    Vue.set(state.ticket.info, "type", data.type);
    Vue.set(state.ticket.info, "group", data.group);
    Vue.set(state.ticket.info, "mark", data.mark);
    Vue.set(state.ticket.info, "monger_id", data.monger_id);

    if (data.group === ACCOUNTING_GROUP.MONGER){
      Vue.set(state.ticket.accounting, "id", data.mo.id);
      Vue.set(state.ticket.accounting, "count", data.mo.count);
      Vue.set(state.ticket.accounting, "status", data.mo.status);
      Vue.set(state.ticket.accounting, "price", data.mo.price);
      Vue.set(state.ticket.accounting, "date", data.mo.create);
      Vue.set(state.ticket.accounting, "type", data.mo.type);
    }

    if (data.group === ACCOUNTING_GROUP.THEATER){
      Vue.set(state.ticket.accounting, "id", data.payment.id);
      Vue.set(state.ticket.accounting, "count", data.payment.ticket_count);
      Vue.set(state.ticket.accounting, "status", data.payment.status_id);
      Vue.set(state.ticket.accounting, "price", data.payment.price);
      Vue.set(state.ticket.accounting, "date", data.payment.create);
      Vue.set(state.ticket.accounting, "type", data.payment.type);
    }

    if (data.isPushka){
      Vue.set(state.ticket.pushka, "rid", data.pushka.rid);
      Vue.set(state.ticket.pushka, "email", data.pushka.email);
      Vue.set(state.ticket.pushka, "fio", data.pushka.fio);
      Vue.set(state.ticket.pushka, "phone", data.pushka.phone);
      Vue.set(state.ticket.pushka, "rqr", data.pushka.qr);
      Vue.set(state.ticket.pushka, "status", data.pushka.type);
      Vue.set(state.ticket.pushka, "user", data.pushka.user_id);
    }

    Vue.set(state.ticket, "level", TICKET_LEVEL.LOCAL_BASE_GOOD);

  },

  setTicketGoodEmulation(state, data){


    // console.log(state.storage.one_event.id);
    // console.log(data.event.id);


    Vue.set(state.ticket.flag, "isEmulateGood", data.b);
    Vue.set(state.ticket.flag, "isEmulateOneEventGood", (state.storage.one_event.id === data.event.id));

    Vue.set(state.ticket.event, "id", data.event.id);
    Vue.set(state.ticket.event.date, "day", data.event.date.day);
    Vue.set(state.ticket.event.date, "month", data.event.date.month_rname);
    Vue.set(state.ticket.event.date, "year", data.event.date.year);
    Vue.set(state.ticket.event.date, "time", data.event.date.time);

    Vue.set(state.ticket.show, "id", data.event.show_id);
    Vue.set(state.ticket.show, "name", data.event.show_name);
    Vue.set(state.ticket.show, "age", data.event.show_age);

    Vue.set(state.ticket.seat, "id", data.seat.id);
    Vue.set(state.ticket.seat, "sector", data.seat.sector_id);
    Vue.set(state.ticket.seat, "sector_name", "Определить");

    let sector = state.storage.sectors.find(sector => sector.ls_id == data.seat.sector_id);
    if (sector !== undefined){
      Vue.set(state.ticket.seat, "sector_name", sector.ls_name);
    }

    Vue.set(state.ticket.seat, "row", data.seat.row);
    Vue.set(state.ticket.seat, "chair", data.seat.chair);

    Vue.set(state.ticket, "level", TICKET_LEVEL.EMULATE_GOOD);


  },

  clearTicket(state, qr){

    console.log('clear');


    Vue.set(state, "ticket", {
      flag:{
        isReady:false,
        isLoading: true,
        isError: true,

        isPushka: false,

        isPushkaReady: false,
        isPushkaLoading: false,
        isPushkaActive: false,
        isPushkaVisited: false,

        isEmulateGood: false,
        isEmulateOneEventGood: false,
        isLocalBaseGood: false,

        isOriginal: true,
      },
      info:{
        QR: qr,
        type: ACCOUNTING_TYPE.NONE,
        group: ACCOUNTING_GROUP.NONE,
        mark: "NO",
        monger_id: 0,
        original_id: 0,
        scan_mode: settings.state.settings.work_mode,
        time: new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' '),
      },
      show:{},
      event:{
        id:0,
        date:{}
      },
      seat:{},
      accounting:{},
      pushka:{},

      level: TICKET_LEVEL.TOTAL_BAD,
    });

  },

  setAfishaStatusB(state, data){
    Vue.set(state.eventAfishaScan, "b", data);
  },

  setAfishaStatusR(state, data){
    Vue.set(state.eventAfishaScan, "r", data);
  },

  setAfishaData(state, data){
    Vue.set(state.eventAfishaScan, "data", data);
  },

  setStorageEvents(state, data){
    Vue.set(state.storage, "events", data);
  },



  setStorage(state, data){

    console.log(data);
    //commit("setStorage", JSON.parse(JSON.stringify(data.events)) );

    Vue.set(state.storage, "events", JSON.parse(JSON.stringify(data.events)));
    Vue.set(state.storage, "layers", JSON.parse(JSON.stringify(data.layers.lay)));
    Vue.set(state.storage, "sectors", JSON.parse(JSON.stringify(data.sectors)));
    Vue.set(state.storage, "ticket_types", JSON.parse(JSON.stringify(data.ticket_types)));

    Vue.set(state.storage.flag, "isEmualteAfishaLoaded", true);


  },


  setMongerListB(state, data){
    Vue.set(state.mongerList, "b", data);
  },

  setMongerListR(state, data){
    Vue.set(state.mongerList, "r", data);
  },

  setMongerListData(state, data){
    Vue.set(state.mongerList, "data", data);
  },


  setQR_eventB(state, data){
    Vue.set(state.qr_event_data, "b", data);
  },

  setQR_eventR(state, data){
    Vue.set(state.qr_event_data, "r", data);
  },

  setQR_eventID(state, data){
    Vue.set(state.qr_event_data, "event", data);
  },

  setQR_eventData(state, data){
    Vue.set(state.qr_event_data, "data", data);
  },


  setStorageOneEvent(state, data){

    console.log(state, data);
    console.log(data);

    Vue.set(state.storage.one_event, "id", data.event.id);
    Vue.set(state.storage.one_event, "accounting", JSON.parse(JSON.stringify(data.accounting)));
    Vue.set(state.storage.one_event, "seats", JSON.parse(JSON.stringify(data.seats)));

    Vue.set(state.storage.flag, "isOneEventLoaded", true);

  },



  setCurrentEventScan(state, data){

    //console.log(data);

    Vue.set(state.currentEventScan, "b", true);
    Vue.set(state.currentEventScan, "event", data.event.id);
    Vue.set(state.currentEventScan, "data", data);
  },


  // распознаем что мы отсканировали и раздаем поля и флаги
  // никуда в базы не лезем, просто разбиваем отсканированную строку на поля
  // пишем данные в current_scan_info.info и current_scan_info.flag
  doScan_infoline_suss(state,  txt_scan){

    //txt_scan = '! IM 435-10416-3637-6827';
    //txt_scan = '! IM 1--6699-194'; // инет
    //txt_scan = '! IM 894--14639-11172'; // инет
    //txt_scan = '!  IM 416-10206-9187-10111'; // инет
    //console.log(txt_scan.charCodeAt(0));

    // уберем двойные пробелы внутр
    txt_scan = txt_scan.replace(/ {1,}/g," ");

    console.log(txt_scan, state.current_scan_info.txt_scan)

    // раздаем начальные значения
    state.current_scan_info.flag.isRun        = true;
    state.current_scan_info.flag.isParseOk    = false;
    state.current_scan_info.flag.isDoubleNow  = false;
    state.current_scan_info.flag.isOurQR      = false;

    // сканы разные - делаем (чтоб двойных не было)
    if (txt_scan !== state.current_scan_info.txt_scan) {

      state.current_scan_info.txt_scan = txt_scan;
      state.current_scan_info.b = false;
      state.current_scan_info.r = true;

      // мои сканы не более 32 символов
      if (txt_scan.length<33){

        // Первый знак это ! - code 33
        if (txt_scan.charCodeAt(0)===33){

          // теперь можно разворачивать чего у нас там в codeline
          state.current_scan_info.flag.isOurQR      = true;

          // пробуем распарсить значение внутри
          try {

            let r = txt_scan.split(' ');
            //console.log(r);
            state.current_scan_info.info.monger_mark = r['1'];

            let r1 = r['2'].split('-');
            //console.log(r1);
            state.current_scan_info.info.event    = parseInt(r1['0']);
            state.current_scan_info.info.serial   = parseInt(r1['1']);
            state.current_scan_info.info.seat_id  = parseInt(r1['2']);
            state.current_scan_info.info.paydoc   = parseInt(r1['3']);

            // все хорошо, считаем, что распарсили как надо
            state.current_scan_info.b = true;

            state.current_scan_info.flag.isRun        = false;
            state.current_scan_info.flag.isOurQR      = true;
            state.current_scan_info.flag.isParseOk    = true;
            state.current_scan_info.flag.isDoubleNow  = false;

            window.navigator.vibrate([100,30]);

          }
          catch (e){
            console.log('Что-то внутри не распарсилось =(');

            state.current_scan_info.flag.isRun        = false;
            state.current_scan_info.flag.isOurQR      = true;     // но считаем, что это наш QR "!"
            state.current_scan_info.flag.isParseOk    = false;
            state.current_scan_info.flag.isDoubleNow  = false;
          }


        }
        else{
          console.log('Первый символ не воскл. знак - мы так не кодируем наши билеты');

          state.current_scan_info.flag.isRun        = false;
          state.current_scan_info.flag.isOurQR      = false;
          state.current_scan_info.flag.isParseOk    = false;
          state.current_scan_info.flag.isDoubleNow  = false;
        }
      }
      else{
        console.log('Сканированная строка больше 32 символов');
        state.current_scan_info.flag.isRun        = false;
        state.current_scan_info.flag.isOurQR      = false;
        state.current_scan_info.flag.isParseOk    = false;
        state.current_scan_info.flag.isDoubleNow  = false;

      }


    }
    // вот только что такой же отсканировал - надо просигналить
    else {

      console.log('Только что вот такой же отсканировали');
      state.current_scan_info.flag.isRun        = false;
      state.current_scan_info.flag.isDoubleNow  = true;
      state.current_scan_info.flag.isParseOk    = true;
      state.current_scan_info.flag.isOurQR      = true;

      window.navigator.vibrate([100,30,100,30,100,200,200,30,200,30,200,200,100,30,100,30,100]);
    }



  },

  // отправить данные на сервер
  addScan(state){

    console.log(state.current_scan_info);

    let post_data = {};

    // распознали - даем полную инфу +-
    if (state.current_scan_info.g){
      post_data = {

        mode: "data",
        raw: state.current_scan_info.txt_scan,

        monger:     state.current_scan_info.info.monger_mark,
        event:      state.current_scan_info.info.event,
        seat:       state.current_scan_info.info.seat_id,
        document:   state.current_scan_info.info.paydoc,
      }
    }
    // непонятно что отсканили
    else{
      post_data = {
        mode: "raw",
        raw: state.current_scan_info.txt_scan,
      }
    }

    console.log(post_data);

    let t = state.api.addScan(post_data);

    console.log(t);

  },


  // должна быть загружена афиша                  [eventAfishaScan]
  // должен быть загружен сегодняшний спектакль   [currentEventScan]
  // должен быть сформирован пул правильных QR    [qr_event_data]
  doScan_infoline(state){

    console.log(state.current_scan_info);

    // додумать еще флагов

    //
    if (!state.current_scan_info.flag.isRun){

      state.current_scan_info.flag.isRunAdd   = true;

      state.current_scan_info.flag.isToday    = false;
      state.current_scan_info.flag.inAfisha   = false;

      // распарсили его норм
      if (state.current_scan_info.flag.isParseOk){

        // это наш сегодняшний
        if(state.currentEventScan.event===state.current_scan_info.info.event){

          state.current_scan_info.flag.isToday    = true;
          state.current_scan_info.flag.inAfisha   = true;

          // бежим по базе qr и ищем его там, добавляем в статистику и тп.
          commonZ.sussQRfromBase_infoline(
              state.current_scan_info,
              state.qr_event_data,
              state.mongerList.data,
              state.scan_statistics_local);

        }
        else{
          console.log("Спектакль-то не сегодня будет");
          state.current_scan_info.flag.isToday = false;

          // он вообще в афише, или прошел?
          // пробег по циклу с афишей
          state.eventAfishaScan.data.events.forEach(event =>{
            // нашли в афише
            if (event.id === state.current_scan_info.info.event){
              state.current_scan_info.flag.inAfisha   = true;
              state.current_scan_info.flag.isRunAdd   = false;

              state.current_scan_info.suss.show = event.show_name;
              state.current_scan_info.suss.date = event.timedate;
            }
          })

          // если не нашли его в афише - делаем запрос на сервер напрямую
          if (!state.current_scan_info.flag.inAfisha ){
            console.log('Не нашли в афише, делаем прямой запрос на сервер')
          }

        }



      }
      else{
        console.log('Чет билет не распарсился');

        if (state.current_scan_info.flag.isOurQR){
          console.log("Вроде наш - давай отправил напрямую запрос на сервер с этим QR, а там посмотрим")
        }
        else{
          console.log("Отсканили дичь какую-то, пулять никуда не буду, но в лог ошибок запишу")
        }
      }

    }
    else{
      console.log('По какой-то причине еще идет распознование, так что анализировать низя');

    }

    //console.log(state.current_scan_info.txt_scan);

    //   // прописать условия
    //   // если загружена база, то ищем в ней

    //
    //   // если нет базы - посылаем прямой запрос на север
    //
    //   // если и сети нет - ругаемся =(

    // ищем в загруженной базе
    //console.log(state.currentEventScan);
    //console.log(state.current_scan_info);
    //console.log(state.qr_event_data);
    //console.log(state.eventAfishaScan);


    // плохой билет - нигде не нашли
    state.current_scan_info.g = false;

    // смотрим вообще что это за спектакль - похож на наш?
    // это сегодня, значит ищем в базе qr
    if(state.currentEventScan.event===state.current_scan_info.info.event){

      console.log('event==');

      // не отсюда вытаскивать, а из qr_event_data
      // state.current_scan_info.suss.show = state.currentEventScan.data.show.name;
      // state.current_scan_info.suss.date = state.currentEventScan.dataevent.timedate;

      state.current_scan_info.suss.show = state.qr_event_data.show;
      state.current_scan_info.suss.date = state.qr_event_data.date;

      //console.log('Спектакль правильный');

      let bb1 = false;
      // ищем среди qr сегодняшнего спектакля
      state.qr_event_data.data.forEach(base_qr=>{
        //console.log(base_qr+' 7');

        // совпадает кресло и платежка - или кресло и mo - в зависимости от продавца - надо
        // monger list подгружать и смотреть
        if ((state.current_scan_info.info.seat_id==base_qr.id)&&
            (state.current_scan_info.info.paydoc==base_qr.payment)){

          state.current_scan_info.g = true;
          state.current_scan_info.suss.sector = base_qr.sector;
          state.current_scan_info.suss.row    = base_qr.row;
          state.current_scan_info.suss.seat   = base_qr.chair;

          if (!base_qr.scanned){
            state.scan_statistics_local.total.count++;

            // приплюсовать в статистику к нужному продавцу
            // топорно - надо сопоставлять с загруженным списком продавцов !!
            if (state.current_scan_info.info.monger_mark=="IM"){
              // так тоже нельзя - надо через find - певрым может быть любой
              state.scan_statistics_local.event[0].count++;
            }

            if ((state.current_scan_info.info.monger_mark=="KSG")||(state.current_scan_info.info.monger_mark=="KST")){
              // так тоже нельзя - надо через find - певрым может быть любой
              state.scan_statistics_local.event[1].count++;
            }

            if (state.current_scan_info.info.monger_mark=="USH"){
              // так тоже нельзя - надо через find - певрым может быть любой
              state.scan_statistics_local.event[2].count++;
            }

            if (state.current_scan_info.info.monger_mark=="UM"){
              // так тоже нельзя - надо через find - певрым может быть любой
              state.scan_statistics_local.event[3].count++;
            }





            base_qr.scanned = true;
          }
          else{
            //console.log('Уже сканировали билет - подсветить его синим!');
          }



          //console.log('Билет правильный');
          bb1 = true;
        }

      });

      if (!bb1){
        //console.log('Спектакль правильный, но билет на найден - сделать прямой запрос в базу - может толькоко что купили?')
      }


    }
    // не сегодня, ищем в загруженной афише - что за название
    else{
      let bb = false;
      //console.log('1');
      state.eventAfishaScan.data.events.forEach(event =>{
        // нашли в афише
        if (event.id == state.current_scan_info.info.event){
          //console.log('in actual');
          bb = true;

          state.current_scan_info.suss.show = event.show_name;
          state.current_scan_info.suss.date = event.timedate;


        }
      })

      // не нашли в афише - можно сразу сказать - билет лесом, а можно сделать чистый запрос к базе
      if (!bb) {
        //console.log('not in actual event - need send direct server que');
      }

    }








  },

  // подгрузить данные для отрисовки зала
  loadEventMapKassa(state, event_id){

    //console.log('1');

    let pr = state.api.loadEvent_Kassa(event_id);

    pr.then( data =>{
      state.eventMapKassa = {
        b:true,
        data: data
      }
    })

    //console.log('2');

  },








  // подготовить ожидания по билетам на сегоняшний спектакль
  compileLegend(state){

    state.scan_statistics_local = {};
    state.scan_statistics_local.event = [];
    state.scan_statistics_local.error = [];
    state.scan_statistics_local.total = {
      order: 100,
      name: "ИТОГО",
      guess: 0,
      mark:  "SUM",
      count: 0,
      color: "red"

    };



    //console.log(state.currentEventScan.data.legend_group);

    // перебор объекта
    //state.currentEventScan.data.legend_group

    for (let key in state.currentEventScan.data.legend_group) {

      let group = state.currentEventScan.data.legend_group[key];

      let group_mark = "none";
      let color = "black";

      if (key==='501'){
        group_mark = "IM";
        color = 'green';
      }
      if (key==='502'){
        group_mark = "KS";
        color = "orange";
      }

      // уполномоченные - их отдельно записать
      if (key==='503'){
        // перебираем их внутри еще
        for (let key_m in group.more) {
          let monger = group.more[key_m];

          let t = {};
          t.order       = 100 + monger.id;    // 501 - 500 = 1 инет, 2 касса, 3 уполномоченные
          t.name        = monger.name;
          t.guess       = monger.count;
          t.mark        = monger.mark;
          t.group_mark  = "MNG";
          t.count       = 0;
          t.color       = "blue";

          state.scan_statistics_local.event.push(t);

          state.scan_statistics_local.total.guess += t.guess;
        }

      }
      else{
        let t = {};
        t.order       = key-500;    // 501 - 500 = 1 инет, 2 касса, 3 уполномоченные
        t.name        = group.name;
        t.guess       = group.count;
        t.mark        = group_mark;
        t.group_mark  = group_mark;
        t.count       = 0;
        t.color       = color;

        state.scan_statistics_local.event.push(t);

        state.scan_statistics_local.total.guess += t.guess;
      }
    }

    //console.log(state.scan_statistics_local);


  },

  // обработать и сформировать массив qr
  compileQRData(state){


    //console.log(state.currentEventScan);

    for (let key in state.currentEventScan.data.seats){

      let seat = state.currentEventScan.data.seats[key];

      if (seat.qr.exist){

        // доставать сюда monger mark из отдельного запроса
        let t = {};
        t.id          = seat.id;
        t.monger      = seat.monger;

        t.payment     = seat.payment;
        t.mo          = seat.mo;

        t.sector      = seat.sector_id;
        t.row         = seat.row;
        t.chair       = seat.chair;

        t.scanned     = false;
        t.scan_time   = "";
        //t.


        t.qr      = "! "+seat.qr.infoline;

        state.qr_event_data.data.push(t)
      }
    }


    state.qr_event_data.event   = state.currentEventScan.data.event.id;
    state.qr_event_data.show    = state.currentEventScan.data.show.name;
    state.qr_event_data.date    = state.currentEventScan.data.event.timedate;
    //state.qr_event_data.age     = state.currentEventScan.data.event.id;
    //state.qr_event_data.time    = state.currentEventScan.data.event.id;



    state.qr_event_data.b = true;
    state.qr_event_data.r = false;

    //console.log(state.qr_event_data);
  }


};


const motion = {

  findEventInAfisha(event_id){
    return state.storage.events.find(event=> event.id === event_id)
  },

  findSeatInLayer(seat_id, layer_id){
    return state.storage.layers[layer_id].seat_positions.find(seat => seat.id === seat_id);
  },

  findPaymentInOneEvent(payment_id){
    return state.storage.one_event.accounting.payments_data.find(payment => payment.id === payment_id);
  },

  findMOInOneEvent(mo_id){
    return state.storage.one_event.accounting.mo_data.find(mo => mo.id === mo_id);
  },

  findPushkaInOneEvent(seat_id){
    return state.storage.one_event.accounting.pushka.find(pushka=>pushka.seat_id === seat_id);
  },

  // может ли быть такой билет потенциально - проверка на офлайн всегда
  searchTicketInEmulateMode(event_id, seat_id){

    let res = {
      b: false,
      event: undefined,
      seat: undefined,
    }

    res.event = motion.findEventInAfisha(event_id);

    if (res.event !== undefined){
      res.seat = motion.findSeatInLayer(seat_id, res.event?.lay_id);
      res.b = res.seat !== undefined;
    }

    return res;
  },

  // проверять и место и платежку - чтоб они к одному SO принадлежали
  // а то base offline просто потенциал смотрит, без конкретики
  // accounting_id это или payment или mo
  searchTicketInOneEvent(event_id, seat_id, accounting_id){

    let res = {

      b: false,

      type:   ACCOUNTING_TYPE.NONE,
      group:  ACCOUNTING_GROUP.NONE,

      mark: "no monger mark",
      monger_id: 0,

      isPushka: false,
      pushka: undefined,

      payment: {
        id: 0,
      },

      mo:{
        id:0
      },
    };

    res.pushka = motion.findPushkaInOneEvent(seat_id);
    res.isPushka = res.pushka !== undefined;
    res.monger_id = state.storage.one_event.seats[seat_id].monger;

    // разбираем чей билет
    //console.log(state.storage.one_event.seats[seat_id]);

    switch (state.storage.one_event.seats[seat_id].status){

      case SEAT_STATUS.SALE_INET:
        res.type = ACCOUNTING_TYPE.INET;
        res.group = ACCOUNTING_GROUP.THEATER;
        break;

      case SEAT_STATUS.SALE_KASSA_CARD:
      case SEAT_STATUS.SALE_KASSA_NAL:
        res.type = ACCOUNTING_TYPE.KASSA
        res.group = ACCOUNTING_GROUP.THEATER;
        break;

      case SEAT_STATUS.BRON_MONGER_MO:
        res.type = ACCOUNTING_TYPE.MONGER;
        res.group = ACCOUNTING_GROUP.MONGER;
        break;
    }

    switch (res.group){
      case ACCOUNTING_GROUP.THEATER:

        res.payment = motion.findPaymentInOneEvent(accounting_id);
        // был в платежах, и совпадало в SO все три переменные,
        res.b = (
            (state.storage.one_event.accounting.payments_ids.includes(accounting_id)) &&
            (state.storage.one_event.seats[seat_id].id === seat_id) &&
            (state.storage.one_event.seats[seat_id].payment === accounting_id) &&
            (state.storage.one_event.id === event_id)
        )

        break;

      case ACCOUNTING_GROUP.MONGER:
        console.log('monger order');
        res.mo = motion.findMOInOneEvent(accounting_id);

        // ловить условия для уполномоченных
        res.b = (
            (state.storage.one_event.accounting.mo_ids.includes(accounting_id)) &&
            (state.storage.one_event.seats[seat_id].id === seat_id) &&
            (state.storage.one_event.seats[seat_id].mo === accounting_id) &&
            (state.storage.one_event.id === event_id)
        )

        break;
    }



    return res;
  }



}



export default {
  state,
  getters,
  actions,
  mutations,
  motion
};
